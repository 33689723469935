import { RouterProvider } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { router } from "./routes";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { useEffect, useState, Suspense } from "react";
import Loader from "./components/loader";
import Modal from "./components/modal";
import './resets.css';

function App() {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    window.setTimeout(() => setIsReady(true), 400)
  }, [])

  return (
    <UserAuthContextProvider>
      {isReady ? <Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </Suspense> : <Loader />}
      <Toaster position="bottom-center" />
      <Modal />
    </UserAuthContextProvider>
  );
}

export default App;
