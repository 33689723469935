import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage'
import { getRemoteConfig } from 'firebase/remote-config'

const firebaseConfig = {
    apiKey: "AIzaSyDy_UhRe_KXSQPl6QV--73SiaXhXy0bViU",
    authDomain: "lms-nasci.firebaseapp.com",
    projectId: "lms-nasci",
    storageBucket: "lms-nasci.appspot.com",
    messagingSenderId: "49150310121",
    appId: "1:49150310121:web:ed179f1fc8a28c78892278",
    measurementId: "G-ZFKTJ93PZH"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const config = getRemoteConfig(app);

export default app;