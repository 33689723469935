import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import Support from "../pages/support";

const Login = lazy(() => import('../pages/auth/login'));
const Register = lazy(() => import('../pages/auth/register'));
const Dashboard = lazy(() => import('../pages/dashboard'));

const Home = lazy(() => import('../pages/dashboard/home'))
const Courses = lazy(() => import('../pages/dashboard/courses'))
const NewCourse = lazy(() => import('../pages/dashboard/courses/new course/index'))
const AddContent = lazy(() => import('../pages/dashboard/courses/new course/content'))
const LessonComment = lazy(() => import('../pages/dashboard/lesson'))

const Categories = lazy(() => import("../pages/dashboard/categories"))
const Settings = lazy(() => import("../pages/dashboard/settings"))
const Users = lazy(() => import("../pages/dashboard/users"))
const UserDetails = lazy(() => import('../pages/dashboard/users/details'))

const Report = lazy(() => import('../pages/dashboard/reports'))
const Preview = lazy(() => import('../pages/dashboard/courses/new course/preview'))
const PrivacyPolicy = lazy(() => import('../pages/policy/privacy'))

export const router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute children={<Dashboard />} />,
        children: [
            { index: true, element: <Home /> },
            {
                path: 'courses',
                children: [
                    { index: true, element: <Courses />, },
                    { path: '/courses/:courseId/new', element: <NewCourse />, },
                    { path: '/courses/:courseId/new/:contentId', element: <AddContent /> },
                    { path: '/courses/:courseId/new/:contentId/preview/:lessonId', element: <Preview /> },
                    { path: '/courses/:courseId/lesson', element: <LessonComment /> },
                    { path: '/courses/:courseId/lesson/:lessonId', element: <LessonComment /> },
                ],
            },
            {
                path: 'settings',
                children: [
                    { index: true, element: <Settings />, }
                ],
            },
            {
                path: 'categories',
                element: <Categories />
            },
            {
                path: 'users', children: [
                    { index: true, element: <Users />, },
                    { path: '/users/:userId', element: <UserDetails />, }
                ],
            },
            {
                path: 'reports', element: <Report />,
            },
        ]
    },
    {
        path: "/auth/login",
        element: <Login />,
    },
    {
        path: "/auth/register",
        element: <Register />,
    },
    {
        path: '/support',
        element: <Support />,
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
    }
]);