import { createContext, useContext, useEffect, useState } from "react";
import {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../firebase";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState({});

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }
    function signUp(email, password, name) {
        return callFunction('createAdmin', { email, password, name });
    }

    function logOut() {
        return signOut(auth);
    }

    function googleAuth() {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider)
    }

    function callFunction(name, data) {
        const addMessage = httpsCallable(functions, name);
        return addMessage(data)
    }

    function linkGA() {
        return callFunction('linkToAdmin', {});
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            // console.log("Auth", currentuser);
            setUser(currentuser);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <userAuthContext.Provider
            value={{ user, logIn, signUp, logOut, googleAuth, callFunction, linkGA }}
        >
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}