import { useState } from "react"
import { useTranslation } from "react-i18next"

const Support = () => {
    const { t } = useTranslation()
    const [data, setData] = useState({
        email: 'applications@netactsi.com',
    })

    return (
        <div className="flex items-center flex-col justify-center min-h-screen">
            <div className="text-center text-xl font-semibold mb-3">
                {t('if_issue')}
            </div>
            <div className="flex items-center gap-2">
                {t('email')}:
                <a className="hover:text-blue-500 hover:underline transition" href={`mailto:${data.email}`} target={'_blank'}>{data.email}</a>
            </div>
        </div>
    )
}

export default Support